const StorageHelper = () => {

    const MENU_OPEN = 'menu_open';
    const THEME = 'dao_theme';

    const isBrowser = typeof window !== 'undefined'

    const DAO_TOKEN = 'dao_token';
    const DAO_REFRESH_TOKEN = 'dao_refresh_token';
    const DAO_USER = 'dao_user';

    const setToken = (token: string): void => {
        localStorage.setItem(DAO_TOKEN, token);
    }
    const getToken = (): string | null => {
        let token = localStorage.getItem(DAO_TOKEN);
        if (token && token.length > 0) {
            return token;
        }
        return null;
    }
    const removeToken = (): void => {
        localStorage.removeItem(DAO_TOKEN);
    }

    const setRefreshToken = (token: string): void => {
        localStorage.setItem(DAO_REFRESH_TOKEN, token);
    }
    const getRefreshToken = (): string | null => {
        let token = localStorage.getItem(DAO_REFRESH_TOKEN);
        if (token && token.length > 0) {
            return token;
        }
        return null;
    }
    const removeRefreshToken = (): void => {
        localStorage.removeItem(DAO_REFRESH_TOKEN);
    }

    const setData = (key: string, data: any): void => {
        localStorage.setItem(key, data);
    }
    const getData = (key: string): string | null => {
        let data = localStorage.getItem(key);
        if (data && data.length > 0) {
            return data;
        }
        return null;
    }
    const removeData = (key: string): void => {
        localStorage.removeItem(key);
    }

    const getTheme = (): string => {
        let savedTheme = 'light';
        savedTheme = localStorage.getItem(THEME) ?? 'light';
        return savedTheme;
    }

    const setTheme = (theme: string): void => {
        localStorage.setItem(THEME, theme);
    }

    const getMenuOpen = (): string => {
        let savedMenuOpen = 'open';
        savedMenuOpen = localStorage.getItem(MENU_OPEN) ?? 'open';
        return savedMenuOpen;
    }

    const setMenuOpen = (menuOpen: string): void => {
        localStorage.setItem(MENU_OPEN, menuOpen);
    }

    return {
        setMenuOpen: setMenuOpen,
        getMenuOpen: getMenuOpen,
        setTheme,
        getTheme,
        setToken: setToken,
        getToken: getToken,
        removeToken: removeToken,
        setRefreshToken: setRefreshToken,
        getRefreshToken: getRefreshToken,
        removeRefreshToken: removeRefreshToken,
        setData: setData,
        getData: getData,
        removeData: removeData
    };
}
export default StorageHelper();
