import React, {Component, FC} from 'react'
import {NavLink, useNavigate} from "react-router-dom"
import logo from '../../assets/logo.svg'
import {useSessionStore} from "../../stores/sessionStore";

interface LeftDrawerProps {
}

const LeftDrawer: FC<LeftDrawerProps> = () => {
    const sessionStore = useSessionStore();
    const navigate = useNavigate();

    return (
        <>
            <section className="section section-sidebar offcanvas offcanvas-start show" data-bs-scroll="true"
                     data-bs-backdrop="false" tabIndex={-1} id="sideBar" aria-labelledby="sideBarLabel">
                <div className="logo">
                    <a href="/" title="CVE Logo">
                        <img src={logo} alt='logo'></img>
                    </a>
                </div>
                <div className="offcanvas-body">
                    <div className="sidebar">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/"><i
                                            className="bi bi-bar-chart"></i> Dashboard</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/users"><i
                                            className="bi bi-people"></i> Users</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/topics"><i
                                            className="bi bi-chat-left-dots"></i> Topics</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/notifications"><i
                                            className="bi bi-bell"></i> Notifications</NavLink>
                                    </li>
                                    <div className="menu-divider">SETTINGS</div>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/settings"><i
                                            className="bi bi-sliders"></i> Settings</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/logs"><i
                                            className="bi bi-journal-text"></i> Logs</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => {
                                            sessionStore.logout();
                                            navigate('/login')
                                        }}>
                                            <i className="bi bi-power"></i> Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="section section-sidebar offcanvas offcanvas-start" data-bs-scroll="true"
                     data-bs-backdrop="true" tabIndex={-1} id="sideBarMobile" aria-labelledby="sideBarMobileLabel">


                <div className="offcanvas-body">
                    <div className="sidebar">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/"><i
                                            className="bi bi-bar-chart"></i> Dashboard</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/dao-users"><i
                                            className="bi bi-people"></i> Users</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/topics"><i
                                            className="bi bi-chat-left-dots"></i> Topics</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/notifications"><i
                                            className="bi bi-bell"></i> Notifications</NavLink>
                                    </li>
                                    <div className="menu-divider">SETTINGS</div>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/settings"><i
                                            className="bi bi-sliders"></i> Settings</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/logs"><i
                                            className="bi bi-journal-text"></i> Logs</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/logout"><i
                                            className="bi bi-power"></i> Logout</NavLink>
                                    </li>

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>

            </section>
        </>
    )
}

export default LeftDrawer;
