import React, { FC } from "react";
import ChartActivities from "../../components/ChartActivities/ChartActivities";
import ChartMembers from "../../components/ChartMembers/ChartMembers";
import ChartTopics from "../../components/ChartTopics/ChartTopics";
import ChartPosts from "../../components/ChartPosts/ChartPosts";


interface HomeProps {

}

const Home: FC<HomeProps> = ({ }: HomeProps) => {
    return (
        <>
            <div className="section summary-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 mb-3 mb-lg-0">
                            <ChartMembers></ChartMembers>
                        </div>
                        <div className="col-lg-4 mb-3 mb-lg-0">
                            <ChartTopics></ChartTopics>
                        </div>
                        <div className="col-lg-4 mb-3 mb-lg-0">
                            <ChartPosts></ChartPosts>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section chart-section mt-4 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <ChartActivities></ChartActivities>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Home;
