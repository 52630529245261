import React, { Component } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ScriptableContext,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    scales: {
      x: {
          display: false,
          grid: {
              display: false,
          }
      },
      y: {
          display: false,
          grid: {
              display: false,
          },
      }
  },
    plugins: {
      subtitle: {
        display: false
    },
    legend: {
        display: false
    },
    title: {
        display: false,
        // text: 'Chart.js Bar Chart',
    },
    tooltip: {
        enabled: true
    }

    },
  };
  
  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July',  'Aug', 'Sept', 'Oct', 'Nov'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Topics',
        data: [7, 9, 26, 21, 18, 35, 40, 19, 15, 22, 42, 10],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, "#14b498");
          gradient.addColorStop(1, "#17897900");
          return gradient;
      },
        tension: 0.1,
        fill: true,
        lineTension: 0.5,
        borderWidth: 2,
        pointHoverRadius: 4,
        pointRadius: 1,
      },


    ],
  };

export default class ChartPosts extends Component {
    render() {
        return (

          <div className="card-block card-block-01">
          <div className="d-flex justify-content-between">
              <div className="block-left">
                  <h2>Posts</h2>
                  <p>2403 - Last 30 Days</p>
                  <a href="#" className="details-button" title="More Details"><i
                      className="ti-arrow-circle-right"></i> Details</a>
              </div>
              <div className="block-right">
              <Line options={options} data={data} />
              </div>
          </div>
      </div>
         
        )
    }
}
