import {FC, useEffect, useMemo, useState} from "react";
import {useDataSource} from "../../hooks/useDataSource";
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from "material-react-table";

interface DataTableProps {
    name?: string,
    data: any[],
    columns: MRT_ColumnDef<any>[],
    rowsPerPage?: number
    loading?: boolean
    showSearchBox?: boolean
    showCheckbox?: boolean
    enableSorting?: boolean
    hideHeaderPagination?: boolean
}

const DataTable: FC<DataTableProps> = ({
                                           name,
                                           data,
                                           columns: givenColumns,
                                           rowsPerPage: pageSize,
                                           showSearchBox,
                                           showCheckbox,
                                           loading,
                                           enableSorting,
                                           hideHeaderPagination,
                                       }) => {

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => givenColumns,
        [givenColumns],
    );

    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    });


    return (
        <div className="section section-filter my-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-block card-block-02">
                            <h2 className="card-title">{name}</h2>
                            <div className="card-content">
                                <div className="row">
                                    <MaterialReactTable table={table}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataTable;
