import {create} from "zustand";
import _ from "lodash";
import storageHelper from "../helpers/storageHelper";


export interface SessionStore {
    username: string,
    email: string,
    setUsername: (username: string) => void
    setEmail: (email: string) => void
    hasSession: () => boolean
    login: (data: any) => void,
    logout: () => void,
}

export const useSessionStore = create<SessionStore>()((set, get) => ({
    username: localStorage.getItem('dao_username') || '',
    email: localStorage.getItem('dao_email') || '',
    setUsername: (username: string) => {
        set({username})
    },
    setEmail: (email: string) => {
        set({email})
    },
    hasSession: () => {
        const {username, email} = get();
        return !_.isEmpty(username) && username.length > 0;
    },
    login: (data: any) => {
        storageHelper.setToken(data.access_token)
        storageHelper.setRefreshToken(data.refresh_token)
        storageHelper.setData('dao_username', data.username);
        storageHelper.setData('dao_email', data.email);
        set({username: data.username, email: data.email})
    },
    logout: () => {
        storageHelper.removeToken()
        storageHelper.removeRefreshToken()
        storageHelper.removeData('dao_username');
        storageHelper.removeData('dao_email');
        set({username: '', email: ''})
    },
}))
