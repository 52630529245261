import {create} from "zustand";
import {UserService} from "../services/UserService";
import {ProposalService} from "../services/ProposalService";
import _ from "lodash";

interface ProposalStore {
    loading: boolean,
    service: ProposalService,
    proposals: any[],
    fetch: (refresh?: boolean) => Promise<any[]>
}

export const useProposalStore = create<ProposalStore>()((set, get) => ({
    loading: false,
    service: new ProposalService(),
    proposals: [],
    fetch: async (refresh?: boolean): Promise<any[]> => {
        let {proposals, loading, service} = get();
        let fetchedData: any[] = [];
        if (!loading) {
            if (proposals.length <= 0 || refresh) {
                fetchedData = await service.fetchAll();
                fetchedData = _.orderBy(fetchedData, ['created'], ['desc'])
                set({proposals: fetchedData, loading: false})
            }
        }
        return Promise.resolve(fetchedData);
    },
}))
