import {useEffect, useState} from 'react';
import {useProposalStore} from "../../stores/proposalStore";
import DataTable from "../../components/DataTable/DataTable";
import moment from 'moment';
import _ from "lodash";
import {toast} from "react-toastify";
import {ProposalService} from "../../services/ProposalService";
import ActiveSwitch from '../../components/ActiveSwitch/ActiveSwitch';
import {Button, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2'
import LockedSwitch from "../../components/LockedSwitch/LockedSwitch";

const Topics = () => {
    const proposalService = new ProposalService()
    const proposalStore = useProposalStore();
    const [proposals, setProposals] = useState<any[]>([])

    useEffect(() => {
        if (proposalStore.proposals.length > 0) {
            setProposals(proposalStore.proposals)
        }
    }, [proposalStore.proposals]);

    return (
        <>
            <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-chat-right-dots"></i></div>
                                <div className="title-content">
                                    <h1>Topics</h1>
                                    <p>Browse DAO Topics</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <DataTable
                data={proposals}
                columns={[
                    {
                        accessorKey: 'title',
                        header: 'Title',
                        size: 180,
                        Cell: ({cell}) => {
                            return <a
                                href={`${process.env.REACT_APP_FORUM_URL}/proposal/${(cell.row.original as any).url}`}
                                target="_blank">{(cell.getValue() as any)}</a>
                        }
                    },
                    {
                        accessorKey: 'owner',
                        header: 'Owner',
                        size: 180,
                        Cell: ({cell}) => <span>{_.get((cell.getValue() as any), 'username')}</span>
                    },
                    {
                        accessorKey: 'created',
                        header: 'Created',
                        size: 100,
                        Cell: ({cell}) => <span>{moment((cell.getValue() as string)).format("DD MMM, YYYY")}</span>
                    },
                    {
                        accessorKey: 'replyCount',
                        header: 'Reply Count',
                        size: 100
                    },
                    {
                        accessorKey: 'active',
                        header: 'Active',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <ActiveSwitch checked={Boolean(cell.getValue())} onChange={(checked) => {
                                    proposalService.toggleActive((cell.row.original as any).id).then(resp => {
                                        toast.success(resp.message)
                                        proposalStore.fetch(true)
                                    })
                                }}/>
                            )
                        }
                    },
                    {
                        accessorKey: 'reviewed',
                        header: 'Reviewed',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <ActiveSwitch checked={Boolean(cell.getValue())} onChange={(checked) => {
                                    proposalService.toggleReview((cell.row.original as any).id).then(resp => {
                                        toast.success(resp.message)
                                        proposalStore.fetch(true)
                                    })
                                }}/>
                            )
                        }
                    },
                    {
                        accessorKey: 'locked',
                        header: 'Locked',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <LockedSwitch checked={Boolean(cell.getValue())} onChange={(checked) => {
                                    proposalService.toggleLock((cell.row.original as any).id).then(resp => {
                                        toast.success(resp.message)
                                        proposalStore.fetch(true)
                                    })
                                }}/>
                            )
                        }
                    },
                    {
                        accessorKey: '#actions',
                        header: 'Actions',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <IconButton aria-label="delete" onClick={() => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            proposalService.delete((cell.row.original as any).id).then(() => {
                                                proposalStore.fetch(true).then(() => {
                                                    Swal.fire({
                                                        title: "Deleted!",
                                                        text: "Your proposal has been deleted.",
                                                        icon: "success"
                                                    });
                                                })
                                            })

                                        }
                                    });
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            )
                        }
                    },
                ]}
                name={'Search Proposals'}/>
        </>
    )
};

export default Topics;
