import {useEffect, useMemo, useState} from 'react';
import DataTable from "../../components/DataTable/DataTable";
import {useUserStore} from "../../stores/userStore";
import {UserRole} from "../../models/enums/UserRole";
import _ from "lodash";
import {UserService} from "../../services/UserService";
import {toast} from 'react-toastify';
import ActiveSwitch from "../../components/ActiveSwitch/ActiveSwitch";
import {IconButton} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import {useProposalStore} from "../../stores/proposalStore";

const Users = () => {
    const userStore = useUserStore();
    const proposalStore = useProposalStore();
    const [users, setUsers] = useState<any[]>([])
    const userService = new UserService();

    useEffect(() => {
        if (userStore.users.length > 0) {
            setUsers(userStore.users)
        }
    }, [userStore.users]);

    return (
        <>
            <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-person-down"></i></div>
                                <div className="title-content">
                                    <h1>Users</h1>
                                    <p>Browse DAO Users</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DataTable
                data={users}
                columns={[
                    {
                        accessorKey: 'username',
                        header: 'Username',
                        size: 180,
                    },
                    {
                        accessorKey: 'discordUsername',
                        header: 'Discord Username',
                        size: 180,
                        Cell: ({cell}) => {
                            if (cell.getValue()) {
                                return <span>
                                    {cell.getValue() as string}
                                    <i className="fa-solid fa-certificate ms-2"></i>
                                </span>
                            }
                            return null
                        }
                    },
                    {
                        accessorKey: 'role',
                        header: 'Role',
                        size: 100,
                        Cell: ({cell}) => <span>{_.startCase(UserRole[(cell.getValue() as number)].toString())}</span>
                    },
                    {
                        accessorKey: 'active',
                        header: 'Active',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <ActiveSwitch checked={Boolean(cell.getValue())} onChange={(checked) => {
                                    userService.toggleActive((cell.row.original as any).username).then(resp => {
                                        toast.success(resp.message)
                                        userStore.fetch(true)
                                    })
                                }}/>
                            )
                        }
                    },
                    {
                        accessorKey: '#actions',
                        header: 'Actions',
                        size: 100,
                        Cell: ({cell}) => {
                            return (
                                <IconButton aria-label="delete" onClick={() => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            userService.delete((cell.row.original as any).id).then(() => {
                                                Swal.fire({
                                                    title: "Deleted!",
                                                    text: "Your proposal has been deleted.",
                                                    icon: "success"
                                                });
                                                proposalStore.fetch(true);
                                            })

                                        }
                                    });
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            )
                        }
                    },
                ]}
                name={'Search Users'}/>

        </>
    )
};

export default Users;
