import {AxiosResponse} from "axios";
import apiClient from "../http/apiClient";
import {BaseService} from "./BaseService";

export class AuthenticationService extends BaseService {
    constructor(props?: any) {
        super(props);

    }

    async login(username: string, password: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
                username,
                password
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    /*async register(email: string, username: string, password: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
                email,
                username,
                password
            });
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }*/
}
