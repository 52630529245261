import React, { Component } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {

      legend: {
        labels: {
            font: {
                size: 11
            },
            usePointStyle: true,
            fill: false,
            pointStyle: 'rectRounded',
            padding: 10,
            pointStyleWidth: 32,
            borderRadius: 36,
            borderWidth: 0,
            borderJoinStyle: 'bevel',
        }
    },
  
      title: {
        display: false,
        text: 'Activities',
      },
    },
  };
  
  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July',  'Aug', 'Sept', 'Oct', 'Nov'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Topics',
        data: [65, 59, 80, 81, 56, 55, 40, 70, 32, 26, 32, 30],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 8,
      },
      {
        label: 'Posts',
        data: [15, 29, 40, 11, 66, 35, 20, 10, 28, 34, 39, 80],
        fill: false,
        borderColor: '#db2db7',
        backgroundColor: '#db2db7',
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 8,
      },

    ],
  };

export default class ChartActivities extends Component {
    render() {
        return (
            <div className="card-block card-block-02">
                <h2 className="card-title">Activities</h2>
                <div className="card-content">
                    <Line options={options} data={data} />
                </div>
            </div>
        )
    }
}
