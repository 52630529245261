import {FC, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import {toast} from "react-toastify";


interface ActiveSwitchProps {
    checked: boolean,
    onChange: (checked: boolean) => void
}

const ActiveSwitch: FC<ActiveSwitchProps> = ({checked, onChange}: ActiveSwitchProps): JSX.Element => {

    const [value, setValue] = useState(checked)

    useEffect(() => {
        setValue(checked)
    }, [checked]);

    return (
        <Switch checked={value} onChange={(event, checked) => {
            setValue(checked)
            onChange(checked)
        }}/>
    );
}

export default ActiveSwitch;
