import React from 'react'

export default function Settings() {
    return (
        <>
            <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-sliders"></i></div>
                                <div className="title-content">
                                    <h1>Settings</h1>
                                    <p>Browse Settings</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="section section-filter my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">Set Defaults</h2>
                                <div className="card-content">
                                    <div className="row">
                                        <div className='col-12 mb-3'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={true}></input>
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Membership is Active</label>
                                            </div>

                                        </div>
                                        <div className='col-12 mb-3'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={true}></input>
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Topic creation is Active</label>
                                            </div>
                                        </div>
                                        <div className='col-12 mb-3'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"></input>
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Maintenance Mode</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
