// Assuming PrivateRoute is your custom private route component
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layout/Layout/Layout';
import Home from './pages/Home/Home';
import Topics from './pages/Topics/Topics';
import Users from './pages/Users/Users';
import Login from './pages/Login/Login';
import { useSessionStore } from './stores/sessionStore';
import Post from "./pages/Post/Post";
import Notifications from './pages/Notifications/Notifications';
import Settings from './pages/Settings/Settings';
import Errorlogs from './pages/ErrorLogs/ErrorLogs';
import './App.css';
import './Variables.css';


// Your PrivateRoute component
const PrivateRoute: React.FC = () => {
    // Your authentication logic here
    const sessionStore = useSessionStore();

    return sessionStore.hasSession() ? (
        // If authenticated, render the provided element
        <Routes>
            <Route path="" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="users" element={<Users />} />
                <Route path="topics" element={<Topics />} />
                <Route path="topics" element={<Topics />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="posts" element={<Post />} />
                <Route path="settings" element={<Settings />} />
                <Route path="logs" element={<Errorlogs />} />
            </Route>
        </Routes>
    ) : (
        // If not authenticated, redirect to login
        <Navigate to="/login" />
    );
};

// Your main component
const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<PrivateRoute />} />
            </Routes>
        </Router>
    );
};

export default App;
