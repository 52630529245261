import React, {Component, FC, useEffect} from 'react'
import {Outlet, useParams} from "react-router-dom";
import Header from '../../components/Header/Header'
import LeftDrawer from '../LeftDrawer/LeftDrawer'
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css'
import {ToastContainer} from "react-toastify";
import {useUserStore} from "../../stores/userStore";
import {useProposalStore} from "../../stores/proposalStore";


interface LayoutProps {
}

const Layout: FC<LayoutProps> = () => {
    const userStore = useUserStore();
    const proposalStore = useProposalStore();

    useEffect(() => {

    }, []);

    useEffect(() => {
        userStore.fetch();
        proposalStore.fetch();
    }, []);

    return (
        <>
            <ToastContainer/>
            <Header></Header>
            <LeftDrawer></LeftDrawer>
            <section className='wrapper toggle'>
                <Outlet/>
            </section>
        </>
    )

}

export default Layout;
