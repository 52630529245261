import React, {FC} from "react";

interface PostProps {

}

const Post: FC<PostProps> = ({}: PostProps) => {
    return (
        <>Posts Page</>
    )
};

export default Post;
