import {FC, useEffect, useState} from "react";
import {IconButton, Switch} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

interface LockedSwitchProps {
    checked: boolean,
    onChange: (checked: boolean) => void
}

const LockedSwitch: FC<LockedSwitchProps> = ({checked, onChange}: LockedSwitchProps): JSX.Element => {

    const [value, setValue] = useState(checked)

    useEffect(() => {
        setValue(checked)
    }, [checked]);

    return (
        <IconButton onClick={() => {
            setValue(!checked)
            onChange(!checked)
        }}>
            {
                checked ? <LockIcon/> : <LockOpenIcon/>
            }
        </IconButton>
    );
}

export default LockedSwitch;
