import {create} from "zustand";
import {UserService} from "../services/UserService";
import _ from "lodash";

interface UserStore {
    loading: boolean,
    service: UserService,
    users: any[],
    fetch: (refresh?: boolean) => Promise<any[]>
}

export const useUserStore = create<UserStore>()((set, get) => ({
    loading: false,
    service: new UserService(),
    users: [],
    fetch: async (refresh?: boolean): Promise<any[]> => {
        let {users, loading, service} = get();
        let fetchedUsers: any[] = [];
        if (!loading) {
            if (users.length <= 0 || refresh) {
                fetchedUsers = await service.fetchUsers();
                if (fetchedUsers.length > 0) {
                    fetchedUsers = _(fetchedUsers).sortBy(x => x.username).value();
                }
                set({users: fetchedUsers, loading: false})
            }
        }
        return Promise.resolve(fetchedUsers);
    },
}))
