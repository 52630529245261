import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

//example data type
type TopicList = {
  notID: string;
  notTitle: string;
  notContent: string;
  notDate: string;
  noteAction: string;
};

//nested data is ok, see accessorKeys in ColumnDef below
const data: TopicList[] = [
  {
    notID: '#1',
    notTitle: 'Update 01',
    notContent: 'Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)',
    notDate: '15.12.2023',
    noteAction: 'Edit',
  },
  {
    notID: '#2',
    notTitle: 'Update 01',
    notContent: 'Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)',
    notDate: '15.12.2023',
    noteAction: 'Edit',
  },
  {
    notID: '#3',
    notTitle: 'Update 01',
    notContent: 'Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)',
    notDate: '15.12.2023',
    noteAction: 'Edit',
  },
  {
    notID: '#4',
    notTitle: 'Update 01',
    notContent: 'Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)',
    notDate: '15.12.2023',
    noteAction: 'Edit',
  },
  {
    notID: '#5',
    notTitle: 'Update 01',
    notContent: 'Approval of Hydranet DAO Roadmap (Q4 2023 - Q3 2024)',
    notDate: '15.12.2023',
    noteAction: 'Edit',
  },
];

const Notifications = () => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<TopicList>[]>(
    () => [
      {
        accessorKey: 'notID', 
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'notTitle',
        header: 'Title',
        size: 150,
      },
      {
        accessorKey: 'notContent', 
        header: 'Content',
        size: 320,
      },
      {
        accessorKey: 'notDate',
        header: 'Date',
        size: 150,
      },
      {
        accessorKey: 'noteAction',
        header: 'Action',
        size: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return ( 
    <>
              <div className="section title-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title d-flex justify-content-start align-items-center">
                                <div className="title-icon"><i className="bi bi-bell"></i></div>
                                <div className="title-content">
                                    <h1>Notifications</h1>
                                    <p>Browse Notifications</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="section section-notification my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">Send Notification</h2>
                                <div className="card-content">
                                    <div className="row">
                                    <div className="mb-3">
  <label htmlFor="titleInput" className="form-label">Title</label>
  <input type="text" className="form-control" id="titleInput" placeholder="Enter Title"></input>
</div>
<div className="mb-3">
  <label htmlFor="contentInput" className="form-label">Content</label>
  <textarea className="form-control" id="contentInput"  placeholder="Enter Description"></textarea>
</div>
<div className="mb-3">
  <label htmlFor="linkInput" className="form-label">Link</label>
  <input type="text" className="form-control" id="linkInput" placeholder="Enter Link"></input>
</div>
<div className='col-12'>
<button type="button" className="btn btn-primary">Send Notification</button>
</div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section-filter my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-block card-block-02">
                                <h2 className="card-title">Search Notifications</h2>
                                <div className="card-content">
                                    <div className="row">

                                    <MaterialReactTable table={table} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

  </>
  )
};

export default Notifications;
