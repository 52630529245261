import {AxiosResponse} from "axios";
import apiClient from "../http/apiClient";
import {BaseService} from "./BaseService";
import _ from "lodash";

export class UserService extends BaseService {
    constructor(props?: any) {
        super(props);

    }

    async fetchUsers(): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/user`);
            if (response.status === 200 && _.isArray(response.data)) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async toggleActive(username: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.put(`${process.env.REACT_APP_BASE_URL}/user/toggle-active/${username}`);
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async delete(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.delete(
                `${process.env.REACT_APP_BASE_URL}/user/${id}/delete`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to create reply');
        }
        return Promise.reject('Failed to create reply');
    }

}
